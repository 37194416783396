* {
  box-sizing: border-box;
  --fg: #ccc;
  --bg: #000;
  --en: #c17c7c;
}
html, body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}
body {
  background: url("/images/Background.svg") center/100px var(--bg);
  font-family: serif;
  font-size: 18px;
  line-height: 1.4;
  color: var(--fg)
}
#logo {
  animation: logo 5s infinite alternate ease-in-out;
  width: 200px;
}
[lang="en"] {
  color: var(--en);
}
a, a > [lang="en"] {
  color: inherit;
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 0.5px;
}
a > [lang="en"] {
  color: var(--en);
}
#location {
  font-family: monospace;
  font-size: 80%;
}
#location a {
  margin-right: 8px;
}
@keyframes logo {
  0% {
    opacity: 0.7;
    transform: scale(1.0);
  }
  100% {
    opacity: 1.0;
    transform: scale(0.97);
  }
}
